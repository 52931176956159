<template>
  <div class="footer">
    <div class="footer-head" v-if="!isForm">
      <div>
        <div class="title">为企业打造一站式合规解决方案</div>
        <div class="button" @click="goForm">免费申请产品试用</div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="footer-bottom-container">
        <div class="footer-bottom-container-one">
          <img
            src="../../images/newHome/footer/footer-logo.png"
            alt=""
            class="logo"
          />
        </div>
        <div class="footer-bottom-container-too">
          <div class="too-title title">产品服务</div>
          <div class="line"></div>
          <div class="product" @click="goToOfficialWebsite(1)">
            达芬奇·智能合同管理系统
          </div>
          <div class="product" @click="goToOfficialWebsite(2)">
            达芬奇·智能案件管理系统
          </div>
          <div class="product" @click="goToOfficialWebsite(3)">
            EyeTrust合规管理体系建设平台
          </div>
          <div class="product" @click="goToOfficialWebsite(4)">
            LawTrust数据合规智能管理系统
          </div>
          <div class="product" @click="goForm">企业级法律AI</div>
        </div>
        <div class="footer-bottom-container-three">
          <div class="three-title title">关于我们</div>
          <div class="line"></div>
          <div class="detail" @click="goToXuexi(1)">公司简介</div>
          <div class="detail" @click="goToXuexi(2)">媒体资讯</div>
          <div class="detail" @click="goToXuexi(3)">实务分享</div>
        </div>
        <div class="footer-bottom-container-four">
          <div class="four-title title">联系我们</div>
          <div class="line"></div>
          <div class="detail">
            北京：北京市朝阳区高碑店乡西店村49号楼四层4033
          </div>
          <div class="detail">
            深圳：广东省深圳市南山区滨海大道3398号赛西科技大厦
          </div>
          <div class="detail">
            郑州：河南自贸试验区郑州片区（郑东）东风南路绿地中心北塔51楼5101号
          </div>
          <div class="detail">海南：海南省陵水黎族自治县尔蔚街iLaw陵水学院</div>
        </div>
        <div class="footer-bottom-container-five">
          <div class="five-title title">订阅我们</div>
          <div class="line"></div>
          <div class="qr-code">
            <div style="margin-right: 40px">
              <img
                src="../../images/newHome/footer/footer-gzh.png"
                alt=""
                style="width: 60px"
              />
              <div class="detail">公众号</div>
            </div>
            <div>
              <img
                src="../../images/newHome/footer/footer-vide.jpeg"
                alt=""
                style="width: 60px; border-radius: 4px"
              />
              <div class="detail">视频号</div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom-line"></div>
      <div class="footer-bottom-filings">
        Copyright © 2024 北京合规科技有限公司.All rights reserved.
        <span style="cursor: pointer" @click="beian">京ICP备19014217号</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "./index.scss";
</style>

<script>
export default {
  name: "footerCom",
  props: {
    isForm: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  methods: {
    goForm() {
      const url = window.location.origin;
      window.open(`${url}/appointment`);
    },
    beian() {
      window.open("https://beian.miit.gov.cn/");
    },
    goToOfficialWebsite(type) {
      let url = "";
      if (type === 1) {
        url = "https://contract.dafenqi.law/web-site";
      } else if (type === 2) {
        url = "https://legal.dafenqi.law/web-site";
      } else if (type === 3) {
        url = "https://hgb.heguixueyuan.com/";
      } else {
        url = "https://lawtrust.cn/homePage";
      }
      window.open(url);
    },
    goToXuexi(type) {
      const url = window.location.origin;
      if (type === 1) {
        window.open(`${url}/about`);
      } else if (type === 2) {
        window.open(`${url}/resourceCentre`);
      } else {
        window.open(`${url}/article?type=1`);
      }
    },
  },
};
</script>
