<template>
  <div>
    <div class="nav">
      <div class="nav-content">
        <img class="nav-logo" src="../../images/newHome/ilaw.png" alt="" />
        <div class="nav-navigation">
          <div
            class="nav-navigation-title"
            @click="navClick(1)"
            :class="active === 1 ? 'active' : ''"
          >
            首页
          </div>
          <div
            class="nav-navigation-title"
            style="display: flex; align-items: center"
            @click="navClick(5)"
            :class="active === 5 ? 'active' : ''"
          >
            产品
            <img
              src="../../images/newHome/arrow.png"
              alt=""
              :class="drawer ? 'arrow-transform' : 'arrow'"
            />
          </div>
          <div
            class="nav-navigation-title"
            @click="navClick(2)"
            :class="active === 2 ? 'active' : ''"
          >
            客户案例
          </div>
          <div
            class="nav-navigation-title"
            @click="navClick(3)"
            :class="active === 3 ? 'active' : ''"
          >
            资源中心
          </div>
          <div
            class="nav-navigation-title"
            @click="navClick(4)"
            :class="active === 4 ? 'active' : ''"
          >
            关于我们
          </div>
          <el-button type="primary" round @click="goForm">申请试用</el-button>
        </div>
      </div>
    </div>

    <el-drawer
      :visible.sync="drawer"
      direction="ttb"
      :before-close="handleClose"
      :show-close="false"
      :append-to-body="true"
      custom-class="product-drawer"
      class="drawer"
    >
      <div class="drawer-content">
        <div class="drawer-content-head">
          <div class="content">
            <img
              src="../../images/newHome/prod-system.png"
              alt=""
              class="icon"
            />
            <div class="txt">智能·协同·高效数字化产品</div>
            <div class="line"></div>
            <div class="txt-ash">
              融合法律智慧与科技力量｜为企业打造一站式合规解决方案｜助力企业法务合规业务数字化
            </div>
          </div>
        </div>
        <div class="drawer-content-container">
          <div class="drawer-content-centre">
            <div class="content">
              <div
                v-for="item of platformData"
                :key="item.id"
                @click="selectPlatForm(item)"
              >
                <template v-if="!item.isActive">
                  <div class="content-card">
                    <div class="content-card-head">
                      <img :src="item.icon" alt="" class="icon" />
                      {{ item.title }}
                    </div>
                    <div class="txt-ash">{{ item.txt }}</div>
                    <img
                      :src="item.ai"
                      alt=""
                      v-if="item.id === 4"
                      class="ai"
                    />
                  </div>
                </template>
                <template v-else>
                  <div class="wrap">
                    <div class="card">
                      <img :src="item.iconClick" alt="" class="icon" />
                      <div class="content-card-click">
                        <div class="title">{{ item.title }}</div>
                        <div class="txt-ash">{{ item.txt }}</div>
                        <img
                          :src="item.aiClick"
                          alt=""
                          v-if="item.id === 4"
                          class="ai-click"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="drawer-content-detail">
            <div class="container">
              <div
                v-for="(item, index) of platformDetail"
                :key="index"
                class="container-wrap"
              >
                <div
                  class="card"
                  style="cursor: pointer"
                  v-if="platformType !== 3 && platformType !== 6"
                  @mouseenter="item.isHover = true"
                  @mouseleave="item.isHover = false"
                >
                  {{ item.label }}
                  <div class="button" v-show="item.isHover">
                    <div
                      class="button-item"
                      v-if="item.isOfficialWebsite"
                      @click="goToOfficialWebsite(item.officialWebsite)"
                    >
                      前往官网
                      <img
                        src="../../images/newHome/product-arrow.png"
                        alt=""
                        class="arrow"
                      />
                      <span style="color: rgba(237, 240, 245, 1)"
                        >&nbsp;|&nbsp;</span
                      >
                    </div>
                    <div
                      class="button-item"
                      @click="goLogin(item)"
                      v-show="item.type === 1"
                    >
                      立即登录
                      <img
                        src="../../images/newHome/product-arrow.png"
                        alt=""
                        class="arrow"
                      />
                      <span style="color: rgba(237, 240, 245, 1)"
                        >&nbsp;|&nbsp;</span
                      >
                    </div>
                    <div class="button-item" @click="goForm">
                      立即咨询
                      <img
                        src="../../images/newHome/product-arrow.png"
                        alt=""
                        class="arrow"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="platformType === 3" class="radio">
                <div class="radio-wrap">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      flex-wrap: wrap;
                      height: 200px;
                      width: 670px;
                    "
                  >
                    <div
                      class="radio-card"
                      v-for="(item, index) of platformDetail"
                      :key="index"
                    >
                      <img
                        src="../../images/newHome/radio.png"
                        alt=""
                        class="icon"
                      />{{ item }}
                    </div>
                    <div class="radio-button">
                      <div class="radio-button-item" @click="goToSystem">
                        前往官网
                        <img
                          src="../../images/newHome/product-arrow.png"
                          alt=""
                          class="arrow"
                        />
                        <span style="color: rgba(237, 240, 245, 1)"
                          >&nbsp;|&nbsp;</span
                        >
                      </div>
                      <div class="radio-button-item" @click="handleLogin">
                        立即登录
                        <img
                          src="../../images/newHome/product-arrow.png"
                          alt=""
                          class="arrow"
                        />
                        <span style="color: rgba(237, 240, 245, 1)"
                          >&nbsp;|&nbsp;</span
                        >
                      </div>
                      <div class="radio-button-item" @click="goForm">
                        立即咨询
                        <img
                          src="../../images/newHome/product-arrow.png"
                          alt=""
                          class="arrow"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="platformType === 6" class="qr-code">
                <div class="qr-code-wrap">
                  <div class="qr-code-wrap-container">
                    <div
                      class="radio-card"
                      v-for="(item, index) of platformDetail"
                      :key="index"
                    >
                      <img
                        src="../../images/newHome/radio.png"
                        alt=""
                        class="icon"
                      />{{ item }}
                    </div>
                  </div>
                  <div class="qr-code-card">
                    <img
                      src="../../images/newHome/qr-code.png"
                      alt=""
                      class="icon"
                    />
                    <div class="detail">
                      <img src="../../images/newHome/ilaw-logo.png" alt="" />
                      <div class="detail-title">定制合规解决方案</div>
                      <div class="detail-txt">了解更多合规解决方案</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- <div class="scroll">
      <marquee :behavior="scrollBehavior" :scrollamount="scrollAmount">
        {{ notification }}
      </marquee>
      <span class="close" @click="closeScroll">x</span>
    </div> -->
  </div>
</template>

<style scoped lang="scss">
// .scroll {
//   position: fixed;
//   top: 64px;
//   left: 0;
//   width: 100%;
//   z-index: 1000;
//   marquee {
//     white-space: nowrap;
//     overflow: hidden;
//     color: #333;
//     font-size: 16px;
//     padding: 10px;
//     background-color: #e9e9e9;
//     // border-radius: 5px;
//   }
//   .close {
//     position: absolute;
//     background-color: #e9e9e9;
//     width: 40px;
//     font-size: 20px;
//     top: 6px;
//     right: 0;
//     cursor: pointer;
//   }
// }

@import "./index.scss";
</style>
<style lang="scss">
.v-modal {
  top: 64px !important;
}

.el-drawer {
  height: auto !important;
}
.el-drawer__header {
  display: none;
}
</style>

<script>
export default {
  name: "navCom",
  data() {
    return {
      active: null,
      drawer: false,
      platformData: [
        {
          title: "达芬奇法务综合平台",
          txt: "高效赋能企业法务工作",
          icon: require("../../images/newHome/product-1.png"),
          id: 1,
          isActive: true,
          iconClick: require("../../images/newHome/prod-1-click.png"),
          children: [
            {
              label: "达芬奇·智能合同系统",
              isHover: false,
              type: 1,
              isOfficialWebsite: true,
              officialWebsite: "https://contract.dafenqi.law/web-site",
            },
            {
              label: "达芬奇·智能案件管理系统",
              isHover: false,
              type: 1,
              isOfficialWebsite: true,
              officialWebsite: "https://legal.dafenqi.law/web-site",
            },
            {
              label: "达芬奇·制度管理平台",
              isHover: false,
              type: 0,
              isOfficialWebsite: false,
            },
            {
              label: "达芬奇·证照管理平台",
              isHover: false,
              type: 0,
              isOfficialWebsite: false,
            },
            {
              label: "达芬奇·知产管理平台",
              isHover: false,
              type: 0,
              isOfficialWebsite: false,
            },
            {
              label: "达芬奇·法务课堂平台",
              isHover: false,
              type: 1,
              isOfficialWebsite: false,
            },
          ],
        },
        {
          title: "专项合规治理平台",
          txt: "科技驱动合规",
          icon: require("../../images/newHome/product-2.png"),
          id: 2,
          isActive: false,
          iconClick: require("../../images/newHome/prod-2-click.png"),
          children: [
            {
              label: "数据合规",
              isHover: false,
              type: 1,
              isOfficialWebsite: true,
              officialWebsite: "https://lawtrust.cn/homePage",
            },
            {
              label: "财税合规",
              isHover: false,
              type: 2,
              isOfficialWebsite: false,
            },
            {
              label: "劳动用工合规",
              isHover: false,
              type: 1,
              isOfficialWebsite: false,
            },
            {
              label: "广告营销合规",
              isHover: false,
              type: 2,
              isOfficialWebsite: false,
            },
            {
              label: "知识产权合规",
              isHover: false,
              type: 2,
              isOfficialWebsite: false,
            },
            {
              label: "公司治理",
              isHover: false,
              type: 2,
              isOfficialWebsite: false,
            },
            {
              label: "贸易合规",
              isHover: false,
              type: 2,
              isOfficialWebsite: false,
            },
            {
              label: "ESG(环境、社会和治理)",
              isHover: false,
              type: 2,
              isOfficialWebsite: false,
            },
            {
              label: "反垄断&反不当竞争合规",
              isHover: false,
              type: 2,
              isOfficialWebsite: false,
            },
            {
              label: "反腐败&反商业贿赂",
              isHover: false,
              type: 2,
              isOfficialWebsite: false,
            },
            {
              label: "产品质量",
              isHover: false,
              type: 2,
              isOfficialWebsite: false,
            },
            {
              label: "采购合规",
              isHover: false,
              type: 2,
              isOfficialWebsite: false,
            },
            {
              label: "消费者权益",
              isHover: false,
              type: 2,
              isOfficialWebsite: false,
            },
          ],
        },
        {
          title: "EyeTrust合规眼系统",
          txt: "助力企业合规体系建设",
          icon: require("../../images/newHome/product-3.png"),
          id: 3,
          isActive: false,
          iconClick: require("../../images/newHome/prod-3-click.png"),
          children: [
            "面向全员的合规尽调",
            "科学的风险识别与评估",
            "有针对性的合规培训",
            "全员在线签署《员工合规承诺书》",
            "强证明力的依法公证存证",
          ],
        },
        {
          title: "企业级法律AI",
          txt: "AI赋能的企业级解决方案",
          icon: require("../../images/newHome/product-4.png"),
          ai: require("../../images/newHome/ai.png"),
          id: 4,
          isActive: false,
          iconClick: require("../../images/newHome/prod-4-click.png"),
          aiClick: require("../../images/newHome/ai-click.png"),
          children: [
            { label: "AI合同审查", isHover: false, type: 0 },
            { label: "AI合同履约", isHover: false, type: 0 },
            { label: "AI合规监管态势情报", isHover: false, type: 0 },
            { label: "AI案情分析", isHover: false, type: 0 },
            { label: "AI合规业务咨询", isHover: false, type: 0 },
            { label: "AI智能检索", isHover: false, type: 0 },
            { label: "AI业务审核", isHover: false, type: 0 },
            { label: "AI文书写作", isHover: false, type: 0 },
          ],
        },
        {
          title: "法律大数据平台",
          txt: "全面法律数据库，智能多维检索",
          icon: require("../../images/newHome/product-5.png"),
          id: 5,
          isActive: false,
          iconClick: require("../../images/newHome/prod-5-click.png"),
          children: [
            { label: "案例库", isHover: false, type: 1 },
            { label: "法规库", isHover: false, type: 1 },
            { label: "行政处罚库", isHover: false, type: 1 },
            { label: "检查文书库", isHover: false, type: 1 },
            { label: "实务文章库", isHover: false, type: 1 },
            { label: "司法观点库", isHover: false, type: 1 },
            { label: "优案评析库", isHover: false, type: 1 },
            { label: "类案同判库", isHover: false, type: 1 },
            { label: "公司主体库", isHover: false, type: 1 },
            { label: "期刊论文库", isHover: false, type: 1 },
          ],
        },
        {
          title: "定制化合规解决方案",
          txt: "一站式合规解决方案",
          icon: require("../../images/newHome/product-6.png"),
          id: 6,
          isActive: false,
          iconClick: require("../../images/newHome/prod-6-click.png"),
          children: [
            "专业合规专家1V1交流",
            "企业专属个性化解决方案",
            "一体化法律合规解决方案",
            "高效便捷的智能办公体验",
            "免费体验系统功能",
            "专业团队答疑解惑",
          ],
        },
      ],
      platformDetail: [
        {
          label: "达芬奇·智能合同系统",
          isHover: false,
          type: 1,
          isOfficialWebsite: true,
          officialWebsite: "https://contract.dafenqi.law/web-site",
        },
        {
          label: "达芬奇·智能案件管理系统",
          isHover: false,
          type: 1,
          isOfficialWebsite: true,
          officialWebsite: "https://legal.dafenqi.law/web-site",
        },
        {
          label: "达芬奇·制度管理平台",
          isHover: false,
          type: 0,
          isOfficialWebsite: false,
        },
        {
          label: "达芬奇·证照管理平台",
          isHover: false,
          type: 0,
          isOfficialWebsite: false,
        },
        {
          label: "达芬奇·知产管理平台",
          isHover: false,
          type: 0,
          isOfficialWebsite: false,
        },
        {
          label: "达芬奇·法务课堂平台",
          isHover: false,
          type: 1,
          isOfficialWebsite: false,
        },
      ],
      platformType: 1,
      scrollBehavior: "scroll",
      scrollAmount: 6,
      notification: "这是一条滚动的通知信息...",
    };
  },
  mounted() {
    this.active = null;
    this.routePositioning();
  },
  methods: {
    navClick(tab) {
      this.active = tab;
      if (tab === 5) {
        this.drawer = !this.drawer;
        if (!this.drawer) {
          this.routePositioning();
        }
      }
      if (this.drawer && tab !== 5) {
        this.drawer = false;
      }

      let path = "";
      if (tab === 1) {
        path = "/";
      } else if (tab === 2) {
        path = "/case";
      } else if (tab === 3) {
        path = "/resourceCentre";
      } else if (tab === 4) {
        path = "/about";
      }
      if (path && path !== this.$route.path) {
        this.$router.push({
          path,
        });
      }
    },
    closeScroll() {
      console.log("关闭通知栏");
    },

    routePositioning() {
      let path = this.$route.path;
      if (path === "/") {
        this.active = 1;
      } else if (path === "/case") {
        this.active = 2;
      } else if (path === "/resourceCentre") {
        this.active = 3;
      } else if (path === "/about") {
        this.active = 4;
      }
    },
    goForm() {
      // this.drawer = false;
      const url = window.location.origin;
      window.open(`${url}/appointment`);
    },

    selectPlatForm(item) {
      this.platformData.forEach((el) => {
        el.isActive = false;
      });
      item.isActive = true;
      this.platformDetail = item.children;
      this.platformType = item.id;
      console.log(this.platformType, item);
    },
    handleClose() {
      console.log("handleClose");
    },

    open(url) {
      window.open(url);
    },
    handleLogin() {
      window.open("https://hgb.heguixueyuan.com/login");
    },
    goLogin(item) {
      let url = "";
      if (this.platformType === 1) {
        if (item.label === "达芬奇·智能合同系统") {
          url = "https://contract.dafenqi.law/login";
        } else if (item.label === "达芬奇·智能案件管理系统") {
          url = "https://legal.dafenqi.law/web-site/login";
        } else if (item.label === "达芬奇·法务课堂平台") {
          url = "https://app.lawtrust.cn/login";
        }
      } else if (this.platformType === 2) {
        if (item.label === "劳动用工合规") {
          url = "https://labor.lawtrust.cn/login";
        } else {
          url = "https://app.lawtrust.cn/login";
        }
      } else {
        url = "https://ecdb.lawtrust.cn/login";
      }
      window.open(url);
    },
    goToOfficialWebsite(url) {
      window.open(url);
    },
    goToSystem() {
      window.open("https://hgb.heguixueyuan.com/");
    },
  },
};
</script>
